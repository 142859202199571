<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="employeeData.avatar"
            :text="
              avatarText(employeeData.first_name + ' ' + employeeData.last_name)
            "
            :variant="`light-${resolveUserRoleVariant(employeeData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ employeeData.first_name + " " + employeeData.last_name }}
              </h4>
              <span class="card-text"> *********</span>
              <!-- {{ employeeData.email }} -->
            </div>
            <div class="d-flex flex-wrap">
              <!-- :to="{ name: 'apps-employees-edit', params: { id: employeeData.id } }" -->
              <!-- <b-button
                variant="primary"
              >
                Edit
              </b-button> -->
              <!-- <b-button
                variant="outline-danger"
                class="ml-1"
              >
                Delete
              </b-button> -->
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <!-- <h5 class="mb-0">
                23.3k
              </h5> -->
              <!-- <small>Monthly Sales</small> -->
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="TrendingUpIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <!-- <h5 class="mb-0">
                $99.87k
              </h5> -->
              <!-- <small>Annual Profit</small> -->
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">EmpNo</span>
            </th>
            <td class="pb-50">
              {{ employeeData.employee_no }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ employeeData.status }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">Birth</span>
            </th>
            <td class="pb-50 text-capitalize">
              ****-**-**
              <!-- {{ employeeData.birth_date }} -->
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">Hire Date</span>
            </th>
            <td class="pb-50">
              ****-**-**
              <!-- {{ employeeData.hire_date }} -->
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td>
              **********
              <!-- {{ employeeData.phone_no }} {{ employeeData.email }} -->
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import useEmployeesList from "../employees-list/useEmployeesList";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    employeeData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveUserRoleVariant } = useEmployeesList();
    return {
      avatarText,
      resolveUserRoleVariant,
    };
  },
};
</script>

<style></style>

<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="employeeData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching employee data
      </h4>
      <div class="alert-body">
        No employee found with this employee id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-employees-list'}"
        >
          Employee List
        </b-link>
        for other employees.
      </div>
    </b-alert>

    <template v-if="employeeData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <employee-view-employee-info-card :employee-data="employeeData" />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <employee-view-employee-plan-card :employee-data="employeeData" />
        </b-col>
      </b-row>

      <!-- <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <employee-view-employee-timeline-card />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <employee-view-employee-permissions-card />
        </b-col>
      </b-row> -->

      <payroll-list :employee-data="employeeData" />
      <!-- <payroll-list /> -->
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import PayrollList from '@/views/apps/payroll/payroll-list/PayrollList.vue'
import employeeStoreModule from '../employeeStoreModule'
import EmployeeViewEmployeeInfoCard from './EmployeeViewEmployeeInfoCard.vue'
import EmployeeViewEmployeePlanCard from './EmployeeViewEmployeePlanCard.vue'
// import EmployeeViewEmployeeTimelineCard from './EmployeeViewEmployeeTimelineCard.vue'
// import EmployeeViewEmployeePermissionsCard from './EmployeeViewEmployeePermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    EmployeeViewEmployeeInfoCard,
    EmployeeViewEmployeePlanCard,
    // EmployeeViewEmployeeTimelineCard,
    // EmployeeViewEmployeePermissionsCard,

    PayrollList,
  },
  setup() {
    const employeeData = ref(null)

    const EMPLOYEE_APP_STORE_MODULE_NAME = 'app-employee'

    // Register module
    if (!store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) store.registerModule(EMPLOYEE_APP_STORE_MODULE_NAME, employeeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) store.unregisterModule(EMPLOYEE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-employee/fetchEmployee', { id: router.currentRoute.params.id })
      .then(response => { employeeData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          employeeData.value = undefined
        }
      })

    return {
      employeeData,
    }
  },
}
</script>

<style>

</style>
